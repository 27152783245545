import React from 'react';
import Email from '../../components/Account/Email';
import PrivateRoute from '../../components/PrivateRoute';
import ContextProvider from '../../components/ContextProvider';

const EmailPage = ({ localeCode }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <PrivateRoute path="/account/email" component={Email} />
  </ContextProvider>
);

export default EmailPage;
